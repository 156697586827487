import React from "react"
import { Link } from "gatsby"

import Navbar from "react-bootstrap/navbar"
import Nav from "react-bootstrap/nav"
import Image from "react-bootstrap/image"

import logo from "../../imgs/logo/add_lock_logo_long.png"

import navbarStyles from "./navbar.module.less"

const TopNavbar = () => {
  return (
    <Navbar className={`${navbarStyles.customNav} sticky-top`} expand="md">
      <Link to="/">
        <Navbar.Brand>
          <Image
            src={logo}
            height="40"
            className="d-inline-block align-top"
            alt="Add Lock Storage logo"
          />
        </Navbar.Brand>
      </Link>
      <Navbar.Toggle aria-controls="basic-Navbar-Navbar" />
      <Navbar.Collapse id="basic-Navbar-Navbar">
        <Nav className="mr-auto">
          <div className="nav-link">
            <Link
              to="/rates"
              className={navbarStyles.link}
              activeClassName={navbarStyles.active}
            >
              Rates
            </Link>
          </div>
          <div className="nav-link">
            <Link
              to="/contact"
              className={navbarStyles.link}
              activeClassName={navbarStyles.active}
            >
              Contact Us
            </Link>
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}
export default TopNavbar
