import React from "react"
import { Helmet } from "react-helmet"
import Navbar from "./navbar/navbar"

const PageLayout = ({ children }) => {
  return (
    <div>
      <Helmet>
        <title>Add Lock Storage</title>
      </Helmet>
      <Navbar />
      {children}
    </div>
  )
}

export default PageLayout
